.page {
    width: 100%;
    height: 100%;
    position: absolute;
}

.top-tags {
    bottom: auto;
    top: 35px;
}

.tags {
    color: #ffd700;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 120px;
    font-size: 18px;
    font-family: "La Belle Aurore";
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    will-change: contents;
    opacity: 0;
    transform-style: preserve-3d;
    animation: zoomIn 1s ease-in-out 1s forwards, fadeIn 1s 1s forwards;
}

.about-page,
.contact-page,
.skills-page,
.portfolio-page {
    width: 90%;
    margin-left: 10%;

    .text-zone {
        width: 40%;
        display: flex;
        flex-direction: column;
        max-height: 90%;

        h1 {
            font-size: 53px;
            font-family: "Coolvetica";
            color: #ffd700;
            font-weight: 400;
            margin-top: 0;
            position: relative;
            margin-bottom: 40px;
            left: 10px;

            &::before {
                content: "<h1>";
                font-family: "La Belle Aurore";
                font-size: 18px;
                position: absolute;
                margin-top: -10px;
                left: -10px;
                opacity: 0.6;
                line-height: 18px;
            }

            &::after {
                content: "</h1>";
                font-family: "La Belle Aurore";
                font-size: 18px;
                position: absolute;
                left: -30px;
                bottom: -20px;
                margin-left: 20px;
                opacity: 0.6;
                line-height: 18px;
            }
        }

        p {
            font-size: 14px;
            color: #fff;
            font-family: sans-serif;
            font-weight: 300;
            min-width: fit-content;
            animation: pulse 1s 1s;
            padding-left: 40px;
            text-align: justify;

            &:nth-of-type(1) {
                animation-delay: 1.1s;
            }

            &:nth-of-type(2) {
                animation-delay: 1.2s;
            }

            &:nth-of-type(3) {
                animation-delay: 1.3s;
            }
        }
    }

    .text-animate-hover {
        &:hover {
            color: #fff;
        }
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.9);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@media only screen and (max-width: 960px) {
    .page {
        position: relative;
    }

    .top-tags {
        top: 76px;
    }

    .tags {
        margin-left: 30px;
        left: 0;
    }

    .container {
        flex-direction: column;
        align-items: start;
        height: 100vh;
        overflow: hidden;
        padding-top: 150px;
    }

    .about-page,
    .contact-page,
    .skills-page,
    .portfolio-page {
        width: 100%;
        margin-left: 0;

        .text-zone {
            position: relative;
            width: 80%;
            min-height: 0;
            margin: 0 auto;

            h1 {
                font-size: 36px;
                line-height: 40px;
            }

            p {
                padding-left: 0;
            }
        }
    }
}

@media only screen and (max-width: 1100px) and (max-height: 900px) {
    .about-page,
    .contact-page,
    .skills-page,
    .portfolio-page {
        .text-zone {
            p {
                padding-left: 0;
            }
        }
    }
}

@media only screen and (max-width: 530px) {
    .container {
        height: fit-content;
        min-height: calc(100vh - 150px);
    }
}

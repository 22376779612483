li {
    opacity: 0;
    animation: fadeInUp 2s 2s;
    animation-fill-mode: forwards;

    a {
        text-decoration: none;

        .project-row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .project-row-left {
                display: flex;
                align-items: center;

                .project-selected-wrapper {
                    position: absolute;
                    overflow: hidden;

                    .project-selected {
                        color: white;
                        padding-right: 12px;
                        position: relative;
                        transform: translateX(-100%) translateZ(0);
                    }
                }
            }

            .project-row-right {
                padding-right: 10px;
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    li {
        opacity: 1;
        animation: none;
    }
}

@media only screen and (max-width: 530px) {
    li {
        a {
            .project-row {
                .project-row-right {
                    max-width: 50%;

                    p {
                        text-align: right;
                    }
                }
            }
        }
    }
}

.preloader {
    position: fixed;
    will-change: transform;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #022c43;
    z-index: 2;
    overflow: hidden;
    height: 100%;
    width: 100%;
    animation: appearLeft 1s linear, leaveRight 1s linear 3s forwards;

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 400px;
        max-width: 100%;
        height: 160px;
        text-align: center;

        svg {
            width: 60px;
            height: 70px;
        }

        span,
        .copyright {
            color: #8d8d8d;
            font-size: 10px;
            font-family: "Coolvetica", sans-serif;
            letter-spacing: 3px;
            display: block;
        }

        span {
            margin-bottom: 30px;
        }

        .copyright {
            margin-top: 30px;
        }
    }

    .progress-bar {
        text-align: left;

        .progress-bar_bg {
            height: 1px;
            background: #047765;

            div {
                height: 1px;
                background: #ffd700;
                width: 0;
                animation: widthFull 2s linear 1s forwards;
            }
        }
    }
}

@keyframes widthFull {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes appearLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes leaveRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #fff;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;
        user-select: none;

        &::before {
            content: "<h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after {
            content: "</h1>";
            font-family: "La Belle Aurore";
            color: #ffd700;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            opacity: 0.6;
            animation: fadeIn 1s 1.7s backwards;
        }

        .img-home {
            width: 45px;
            height: 50px;
            margin-left: 20px;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;

            .text-v-home {
                font-size: 70px;
                font-family: "Coolvetica";
                transform: translate(3px, 48px);
                fill: #115173;

                &:first-of-type {
                    transform: translate(0, 50px);
                    fill: #ffd700;
                }
            }
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 20px;
        font-weight: 400;
        font-size: 11px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #ffd700;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #ffd700;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: #ffd700;
            color: #333;
            transition: all 0.3s ease-in-out;
        }
    }
}

@media only screen and (max-width: 960px) {
    .home-page {
        .text-zone {
            position: relative;
            transform: none;
            top: 0;
            bottom: 0;
            left: 50px;
            right: 50px;
            width: 100%;
            min-height: 0;
            z-index: 2;
            margin: auto;
        }

        h2 {
            color: #fff;
            font-weight: 600;
        }
    }
}

@media only screen and (max-width: 530px) {
    .home-page {
        height: calc(100vh - 150px);

        h1 {
            font-size: 36px;
            line-height: 40px;

            &::after {
                left: 0;
                bottom: 35px;
            }
        }
    }
}

.text-zone {
    .tech-tag {
        color: #ffd700;

        &::before {
            content: " ";
        }
    }

    p {
        a {
            color: #ffd700;
            text-decoration: underline;

            &:hover {
                color: #fff;
            }
        }
    }
}

.nav-bar {
    background: #181818;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
        display: flex;
        flex-direction: column;
        padding: 8px 0;

        .img {
            display: block;
            margin: 8px auto;
            width: 50px;
            height: 60px;
            font-family: "Coolvetica";

            .text-v {
                font-size: 50px;
                transform: translate(10px, 35px);
                fill: #115173;

                &:first-of-type {
                    transform: translate(8px, 38px);
                    fill: #ffd700;
                }
            }

            .text-virginie {
                font-size: 14px;
                transform: translate(3px, 55px);
                fill: #fff;
                stroke: none;
            }
        }
    }

    nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-top: -100px;

        a {
            font-size: 22px;
            color: #4d4d4e;
            text-decoration: none;
            position: relative;
            width: 100%;
            height: 51px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                transition: all 0.3s ease-out;
            }

            &:hover {
                color: #ffd700;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: "";
                font-size: 9px;
                letter-spacing: 2px;
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                opacity: 0;
                transition: all 0.3s ease-out;
                text-transform: uppercase;
                text-align: center;
            }
        }

        // a.home-link {
        //     &::after {
        //         content: "accueil";
        //     }
        // }

        // a.about-link {
        //     &:after {
        //         content: "à propos";
        //     }
        // }

        // a.skills-link {
        //     &:after {
        //         content: "savoirs faire";
        //     }
        // }

        // a.portfolio-link {
        //     &:after {
        //         content: "projets";
        //     }
        // }

        // a.contact-link {
        //     &:after {
        //         content: "contact";
        //     }
        // }

        a.active {
            svg {
                color: #ffd700;
            }
        }
    }

    ul {
        position: absolute;
        bottom: 20px;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        list-style: none;
        align-items: center;
        justify-content: space-evenly;

        li {
            a {
                padding: 7px 0;
                display: block;
                font-size: 15px;

                &:hover svg {
                    color: #ffd700;
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .nav-bar {
        width: 100%;
        height: 60px;
        left: 0;
        min-height: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            padding: 0;

            .img {
                width: 40px;
                margin: auto;
                margin-left: 10px;

                .text-v {
                    transform: translate(8px, 33px);
                    font-size: 40px;

                    &:first-of-type {
                        transform: translate(6px, 36px);
                    }
                }

                .text-virginie {
                    font-size: 12px;
                    transform: translate(0px, 50px);
                }
            }
        }

        nav {
            flex-direction: row;
            width: 50%;
            margin-top: 0;
        }

        ul {
            width: 10%;
            height: 100%;
            bottom: 0;
            flex-direction: row;
            position: relative;
        }
    }
}

@media only screen and (max-width: 680px) {
    .nav-bar {
        position: fixed;

        nav,
        ul {
            pointer-events: none;
            opacity: 0;
            transform: translateX(100%);
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        }

        .hamburger-open {
            pointer-events: initial;
            opacity: 1;
            transform: translateX(0);
            transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
        }

        ul {
            li {
                a {
                    padding: 0 10px;
                    font-size: 22px;
                }
            }
        }
    }
}

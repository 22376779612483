.modal-wrapper {
    position: fixed;
    top: 0;
    left: -10%;
    width: 110%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #115173;
        padding: 20px;
        border-radius: 5px;
        color: white;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        animation: opacity 0.3s ease-in-out;

        .close-button,
        .modal-links a {
            width: fit-content;
            color: #ffd700;
            background-color: transparent;
            padding: 5px 12px;
            border: 1px solid #ffd700;
            transition: all 0.3s ease-in-out;

            &:hover {
                background: #ffd700;
                color: #333;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
            }
        }

        .close-button {
            position: absolute;

            svg {
                height: 1.5em;
            }
        }

        .modal-top {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;

            img {
                width: 60%;
                border-radius: 5px;
            }

            .modal-top-left {
                width: 40%;
                display: flex;
                flex-direction: column;
                text-align: end;
                padding-right: 20px;

                h2 {
                    margin: 0;
                    font-size: 2em;
                    color: #ffd700;
                }

                ul {
                    list-style: none;
                    padding-left: 20px;
                }

                .modal-category {
                    margin: 20px 0 5px;

                    p {
                        text-align: right;
                    }
                }

                .modal-skills {
                    a {
                        color: #ffd700;

                        &:hover {
                            color: white;
                        }
                    }
                }
            }
        }

        h3 {
            margin: 10px 0 0;
            font-size: 1.5em;
        }

        p {
            margin: 5px 0 0;
            font-size: 1.2em;
            animation: none;
            animation-delay: 0;
        }

        ul {
            list-style: disc;
            scrollbar-width: none;

            li {
                font-size: 1.2em;
                margin-top: 5px;
                opacity: 1;
                animation: none;
                animation-delay: 0;

                .sublevel {
                    font-size: 1em;
                }
            }
        }

        .modal-description {
            margin: 20px;
            text-align: justify;
        }

        .modal-links {
            margin: 20px;

            .links {
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;

                a {
                    font-size: 13px;
                    font-weight: 400;
                    letter-spacing: 4px;
                    font-family: sans-serif;
                    text-decoration: none;
                    white-space: nowrap;
                    text-transform: uppercase;
                    margin-top: 20px;
                }
            }
        }
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 960px) {
    .modal-wrapper {
        .modal {
            width: 80%;
            transform: translate(-45%, -50%);
        }
    }
}

@media only screen and (max-width: 1100px) and (max-height: 900px) {
    .modal-wrapper {
        .modal {
            width: 80%;
            transform: translate(-45%, -50%);
        }
    }
}

@media only screen and (max-width: 530px) {
    .modal-wrapper {
        .modal {
            max-height: 60vh;

            .modal-top {
                flex-direction: column;
                margin-top: 40px;

                img {
                    width: 100%;
                }

                .modal-top-left {
                    width: 100%;
                    text-align: start;
                    padding: 10px;

                    .modal-category {
                        p {
                            text-align: start;
                        }
                    }

                    ul {
                        height: auto;
                        padding-left: 0;
                    }
                }
            }

            ul {
                height: auto;
                padding-left: 0;
            }

            .modal-description,
            .modal-links {
                margin: 20px 0;
            }
        }
    }
}

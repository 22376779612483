.portfolio-page {
    .text-zone {
        max-height: 60%;

        .category-selector {
            display: flex;
            align-items: center;
            justify-content: end;
            margin-bottom: 20px;

            label {
                color: #fff;
                font-size: 14px;
                font-weight: bolder;
                margin-right: 10px;
            }

            select {
                width: max-content;
                color: #ffd700;
                border: solid 1px #115173;
                background-color: #115173;
                border-radius: 5px;
            }
        }

        ul {
            margin: 0;
            list-style: none;
            overflow-y: auto;
            height: 100%;
            scrollbar-color: #ffd700 #115173;
            scrollbar-width: thin;
        }
    }

    .project-overview {
        width: 50%;
        border-radius: 5px;

        .overview {
            position: relative;
            width: 100%;
            height: 100%;
            right: -5px;
            object-fit: scale-down;
            border-radius: 5px;
            opacity: 0;
            animation: right 2s 0.1s forwards;
        }
    }
}

@keyframes right {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@media only screen and (max-width: 960px) {
    .portfolio-page {
        .text-zone {
            ul {
                padding: 0;
            }
        }

        .project-overview {
            display: none;
        }
    }
}

@media only screen and (max-width: 1100px) and (max-height: 900px) {
    .portfolio-page {
        .text-zone {
            width: 90%;
        }
        .project-overview {
            display: none;
        }
    }
}

@media only screen and (max-width: 530px) {
    .portfolio-page {
        .text-zone {
            ul {
                height: 60vh;
            }
        }
    }
}

.logo-container {
    position: absolute;
    top: 0;
    right: 15%;
    bottom: 0;
    left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;

    svg {
        width: 510px;
        height: 510px;
        transform: rotateZ(10deg);

        text {
            font-family: "Coolvetica";
            transform: translate(15px, 495px);
            font-size: 700px;

            &:first-of-type {
                transform: translate(0, 505px);
            }
        }
    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        z-index: 1;
        animation: graduateOpacity 8s;

        text {
            fill: #115173;

            &:first-of-type {
                transform: translate(0, 505px);
                fill: #ffd700;
            }
        }
    }

    .svg-container {
        text {
            fill: none;
            stroke: #ffd700;
            stroke-width: 0.5;
            stroke-dasharray: 2060;
            stroke-dashoffset: 100;
            animation: dash 7s linear normal;
        }
    }
}

@keyframes dash {
    from {
        stroke-dashoffset: 2060;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes graduateOpacity {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 960px) {
    .logo-container {
        opacity: 0.5;
        right: 0;
        left: 0;

        .solid-logo {
            left: auto;
        }
    }
}

@media only screen and (max-width: 1100px) and (max-height: 900px) {
    .logo-container {
        right: 2%;
    }
}

@media only screen and (max-width: 530px) {
    .logo-container {
        right: 0;

        .svg-container {
            position: absolute;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
        }
    }
}

.skill-charts {
    position: absolute;
    width: 60%;
    top: 0;
    bottom: 0;
    right: 50px;
    height: 100%;
    margin: auto;
    color: #fff;
    font-size: 30px;
    font-family: "Coolvetica";
    display: flex;
    justify-content: end;
    align-items: center;
}

@media (max-width: 960px) {
    .skill-charts {
        position: relative;
        left: 0;
        width: 80%;
        height: auto;
        padding-top: 10%;
        overflow: hidden;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 1100px) and (max-height: 900px) {
    .skill-charts {
        font-size: 20px;
    }
}

@media only screen and (max-width: 530px) {
    .skill-charts {
        padding-top: 0;
        font-size: 15px;
    }
}
